.statistics .title {
  position: relative;
  margin-bottom: -30px;
  text-align: center;
  z-index: 1;
  color: gray;
}

.statistics .blocker {
  position: relative;
  bottom: 2rem;
  height: 2rem;
  background: #fff;
}