.documents {
  margin-top: 3.75rem;
  margin-bottom: 1rem;
}

.documents .add {
  margin-bottom: 0.8rem;
}

.documents .file {
  color: #0d6efd;
  cursor: pointer;
}

.documents .file.admin,
.documents .actions.reseller {
  display: none !important;
}

.documents .actions font {
  cursor: pointer;
}

.documents .section {
  border: 2px solid lightgray;
  padding-bottom: 20px;
  margin-bottom: 2rem;
  border-radius: 0.375rem 0.375rem 0 0;
}

.documents .section.hide {
  border-radius: 0.375rem;
  overflow: hidden;
}

.documents .accordion {
  font-size: 1.25rem;
  padding: 10px;
  border-bottom: 2px solid lightgray;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: none !important;
  cursor: pointer;
}

.documents .section.hide .accordion {
  border-bottom: none;
}

.documents .accordion span {
  position: relative;
  top: -2px;
  left: 5px;
}

.documents .table {
  max-height: 29rem;
  overflow-y: auto;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0 10px;
}

.documents table {
  width: 100%;
}

.documents thead tr th:nth-child(2),
.documents tbody tr td:nth-child(2),
.documents thead tr th:nth-child(3),
.documents tbody tr td:nth-child(3) {
  text-align: center;
}

.documents tbody tr th:nth-child(1) {
  width: 50%;
}

.fileModal .modal-body .mb-3:nth-child(-n+2) {
  margin-bottom: 5px !important;
}