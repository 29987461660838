.options {
  position: absolute;
  top: 13px;
  right: 1rem;
  width: 1px;
}

.options .dots {
  margin-left: -11px;
  border-radius: 50%;
  padding: 3px;
  font-size: 0.775rem;
  background: transparent;
  border-color: transparent;
  min-width: 26px;
  max-width: 26px;
}

.options .dots:hover,
.options.show .dots {
  background: lightgray;
  border-color: lightgray;
}

.options .dots svg {
  vertical-align: text-top;
}

.options .dropdown-menu {
  z-index: 1055;
}

.modal-title img {
  width: 205px;
  position: absolute;
  top: 9px;
  right: 40px;
}

.contact-modal .modal-body img {
  width: 100%;
  margin-bottom: 1rem;
}

.contact-modal .modal-body .col-md-1 {
  border-left: solid 2px lightgray;
  width: 3%;
}