.slider-modal .modal-body {
  padding: 0.1rem;
}

.slider-modal .close {
  position: absolute;
  top: -11px;
  right: 11px;
  z-index: 2;
  font-size: 2.8rem;
  font-weight: lighter;
  color: #fff;
  opacity: 0.5;
  cursor: pointer;
}

.slider-modal .close:hover {
  opacity: 1;
}

.carousel-caption p {
  display: none;
}

.carousel-control-next,
.carousel-control-prev {
  position: fixed;
}

.carousel-indicators {
  position: fixed;
}

.slider-backdrop {
  opacity: 0.8 !important;
}