.input-date {
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  position: relative;
  top: 30px;
  margin-bottom: -5px;
}

.dates {
  position: absolute;
  margin-top: -38px;
  right: 11rem;
}

.dates .date {
  display: inline-block;
  width: 9rem;
}