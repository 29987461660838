.account {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.account .details {
  color: #606060;
  border-bottom: 1px solid lightgray;
  margin-bottom: 2rem;
}

.account .details h6 {
  font-size: 0.9rem;
}

.account .details .col-md-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.account select option:first-of-type {
  font-weight: bold;
}

.account select {
  font-size: 0.7rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.account .redebt {
  width: 15%;
  margin-left: -3rem;
}

.account .currencies {
  width: 10rem;
}

.account .currencies button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  width: 26px;
  height: 26px;
  border-color: #ced4da;
  margin-right: 0.7rem;
}

.account .currencies button:nth-child(2) {
  font-size: larger;
  line-height: 1;
  padding-bottom: 3px;
}

.account .currencies button:disabled {
  color: lightgray;
}

.account .infos {
  border: 2px solid lightgray;
  padding: 20px 30px;
  margin-top: 20px;
}

.account .note {
  text-align: right;
  margin-top: 1rem;
  font-size: 12px;
  color: gray;
}

.accountexport {
  position: absolute;
  margin-top: -41px;
  right: 44px;
}

.filterbuttons {
  margin-top: 12px;
  margin-bottom: 12px;
}

.filterbuttons button {
  font-size: 0.7rem;
  padding: 0;
  width: 10rem;
  height: 25px;
  margin-right: 1rem;
}

.myfilters {
  position: fixed;
  top: 50px;
  z-index: 1;
  width: 100%;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.account .details p {
  background: gray;
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  width: 250px;
  color: #fff;
  margin-top: 7px;
  font-weight: normal;
  z-index: 2;
}

.account .details p::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.account .details svg {
  cursor: pointer;
}

.account .details svg:focus {
  outline: none;
}

.account .dropsearch {
  width: 20rem;
  position: absolute;
  right: 0;
}

.graphLabels.level.investor,
.graphLabels.level.reseller {
  display: inherit;
  visibility: hidden;
}

.account .graphs.level.investor,
.account .graphs.level.reseller.show {
  display: inherit;
  margin-top: -60px;
}

.account .graphs.level.admin .other,
.account .graphs.level.investor .main,
.account .graphs .hide {
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 96.4%;
}

.account .graphs center {
  font-size: 14px;
}

.account .graphs .other h5 {
  margin-bottom: 40px;
}