.investment {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.investment .table {
  max-height: 29rem;
  overflow-y: auto;
}

.investment table {
  width: 100%;
}

.investment tr {
  background: unset;
}

.investment .details {
  border: 2px solid lightgray;
  padding: 20px 30px;
  margin-top: 20px;
}