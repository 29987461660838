.signout {
  position: absolute;
  top: 13px;
  right: 2.5rem;
  width: 1px;
}

.signout .name {
  margin-left: -18px;
  border-radius: 50%;
  padding: 3px;
  font-size: 0.775rem;
  min-width: 26px;
  max-width: 26px;
}

.signout .investor {
  background: green;
  border-color: green;
}

.signout .reseller {
  background: orange;
  border-color: orange;
}

.signout .admin {
  background: red;
  border-color: red;
}