.commissions {
  margin-top: 4.4rem;
  margin-bottom: 1rem;
  padding: 0 30px;
  min-height: calc(100vh - 121.8px);
}

.commissions h4 {
  position: sticky;
  top: 70px;
  background: #F7F7F8;
  padding-top: 3rem;
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: -2px;
}

.commissions .file,
.filesModal .file {
  border: 1px solid #8D8EA3;
  border-radius: 20px;
  padding: 4px 10px;
  color: var(--Tuna-600);
  font-weight: 500;
  cursor: pointer;
  width: 130px;
  margin: auto;
}

.filesModal .file {
  width: unset;
}

.filesModal .title {
  display: inline-block;
  max-width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: clip;
}

/* .filesModal .title:hover:after {
  content: attr(data-title);
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  display: none;
  margin-top: -23px;
  margin-left: -5px;
}

.filesModal .title.show:hover:after {
  display: block;
} */

.filesModal .files div {
  margin-bottom: 20px;
}

.filesModal .files div:last-child {
  margin-bottom: unset;
}

.filesModal .file {
  font-size: 12px;
  float: right;
  padding-top: 4px;
  padding-bottom: 4px;
}

.commissions .file svg,
.filesModal .file svg {
  width: 14px;
  height: 14px;
}

.commissions .comms {
  overflow: clip;
  margin-top: 2px;
  margin-bottom: 0;
}

.commissions .comms thead {
  top: 163px;
  box-shadow: 0 1px 0 #B5B6C4, 0 -1px 0 #B5B6C4;
}
.commissions .comms th,
.commissions .comms td {
  width: 300px;
}

.commissions .comms thead tr th:last-child,
.commissions .comms tbody tr td:last-child {
  text-align: center;
}

.commissions .comms tbody tr th:nth-child(1) {
  width: 50%;
}

.commissions table {
  width: 100%;
}

.commissions .dropsearch {
  position: sticky;
  top: 118px;
  width: 200px;
  float: right;
  margin-top: -45px;
  z-index: 1;
}

.commissions .dropsearch .opts .dropdown-menu {
  width: 30rem;
}

.commissions .dropsearch .opts .res .table td div {
  width: 100%;
}