.totals {
  margin-top: 4rem;
  color: #606060;
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}

.totals .infonote {
  margin-bottom: -1rem;
  margin-top: 1rem;
}

.totals h6 {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
}

.totals h2 {
  font-weight: bold;
}

.totals [class*="col"] {
  border-right: 2px solid lightgray;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.totals [class*="col"]:nth-child(6n+6),
.totals [class*="col"]:last-child {
  border-right: none;
}

.totals [class*="col"]:nth-child(n+7) h2 {
  font-size: 1.7rem;
}

.totals p {
  background: gray;
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  width: 250px;
  color: #fff;
  /* margin-top: -61px; /* At the bottom of the tooltip */
  margin-top: 7px;
  font-weight: normal;
  z-index: 2;
}

.totals p::after {
  content: " ";
  position: absolute;
  /* top: 100%;  /* At the bottom of the tooltip */
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  /* border-color: gray transparent transparent transparent; /* At the bottom of the tooltip */
  border-color: transparent transparent gray transparent; /* At the top of the tooltip */
}

.totals svg {
  cursor: pointer;
}

.totals svg:focus {
  outline: none;
}