.clear {
  margin-top: 5px;
  margin-right: -50px;
  margin-left: 5px;
  width: 45px;
  cursor: pointer;
}

.col-md-1 .clear {
  margin-top: 0;
  margin-left: 7px;
  border-left: 2px solid lightgray;
}

.cleartext {
  position: absolute;
  margin-top: 5px;
  margin-left: 43px;
  font-size: 0.8rem;
  color: gray;
  cursor: pointer;
  display: none;
}

.col-md-1 .cleartext {
  display: initial;
}
