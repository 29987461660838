.dates input[type="date"] {
  width: 0;
  height: 0;
  margin-top: 26px;
  margin-left: 12px;
  margin-right: -40px;
  visibility: hidden;
}

.dates {
  position: absolute;
  margin-top: -38px;
  right: 11rem;
}

.dates .date {
  display: inline-block;
  width: 9rem;
}