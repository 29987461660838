.investorinfo {
  margin-top: 6.5rem !important;
}

.investorinfo h5,
.investorinfo .accts {
  padding: 0 30px;
}

.investorinfo .table {
  max-height: 29rem;
  overflow-y: auto;
}

.investorinfo table {
  width: 100%;
}

/* .investorinfo thead tr th:nth-child(2),
.investorinfo thead tr th:nth-child(3) { */
.investorinfo thead tr:nth-child(2) th:nth-child(1),
.investorinfo thead tr:nth-child(2) th:nth-child(2) {
  text-align: center;
}

.investorinfo tr {
  background: unset;
}

.investorinfo .details {
  border: 2px solid lightgray;
  padding-bottom: 20px;
  margin-top: 20px;
  border-radius: 0.375rem 0.375rem 0 0;
}

.investorinfo .details.hide {
  border-radius: 0.375rem;
}

.investorinfo .accordion {
  margin-top: -3px;
  font-size: 1.25rem;
  padding: 10px;
}

.investorinfo .accordion.active {
  border-radius: 0.375rem 0.375rem 0 0;
}

.investorinfo .accordion:before {
  margin-bottom: 0;
}

.investorinfo .hide {
  overflow: hidden;
}

.investorinfo .hide thead {
  top: 32px;
}

.investorinfo .hide .accountexport {
  display: none;
}


.investorinfo .info {
  margin-top: 0.7rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid lightgray;
  padding: 0 30px;
}

.investorinfo .info .col-md-3 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.investorinfo .infonote {
  padding-left: 30px;
}

.investorinfo tr svg {
  display: block;
  margin: auto;
}

.investorinfo tbody tr td span {
  color: #0d6efd;
  cursor: pointer;
}

/* Remove this when the excel report is ready */
.investorinfo thead tr:nth-child(1) th:nth-child(2),
.investorinfo thead tr:nth-child(2) th:nth-child(1),
.investorinfo tbody tr td:nth-child(1) {
  display: none;
}

.investorinfo .admin thead tr:nth-child(1) th:nth-child(2),
.investorinfo .admin thead tr:nth-child(2) th:nth-child(1),
.investorinfo .admin tbody tr td:nth-child(1) {
  display: table-cell;
}
/* /Remove this when the excel report is ready */

/* Show reseller summary accounts */
.investorinfo .table thead tr:first-child th:first-child span {
  color: #0d6efd;
  cursor: pointer;
}