.investorinfo tr .report {
  display: flex;
  border: 1px solid #8D8EA3;
  border-radius: 20px;
  padding: 4px 10px;
  color: var(--Tuna-600);
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  margin: auto;
}

.investorinfo tr .report svg {
  width: 13px;
  height: 13px;
  margin-left: 0;
}