.newloans {
  color: var(--Tuna-600);
  z-index: 1;
  position: relative;
  float: right;
  margin-top: -12px;
  margin-left: 21px;
  cursor: pointer;
}

.acctpage .newloans,
.docspage .newloans,
.commspage .newloans,
.distspage .newloans {
  display: none !important;
}