.signin {
  position: absolute;
  top: 13px;
  right: 5.5rem;
  width: 10rem;
  border-color: #ced4da;
  padding: 0.13rem;
}

.signin-modal.email .modal-dialog {
  max-width: 575px;
}

.mapblocker {
  position: fixed;
  background: #000;
  opacity: 0.5;
  z-index: 2;
}

.signin-modal,
.mapblocker {
  top: 3.2rem;
  bottom: 2.4rem;
  height: unset;
}

.signin-modal .note span {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}