.loanname {
  background: var(--Tuna-900);
  position: absolute;
  left: -84px;
  padding: 8px;
  border-radius: 6px;
  width: 200px;
  font-size: 13px;
  color: #fff;
  margin-top: 3px;
  text-align: center;
}

/* AdvancedMarkerElement implementation */
/* The position: relative; makes the marker */
/* move from the correct location */
/* .marker {
  position: relative;
}

.marker font {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 9px;
  z-index: 1;
}

.marker svg:first-child,
.marker svg:last-child {
  position: relative;
  z-index: 2;
}

.marker svg:last-child {
  margin-left: -23px;
  margin-top: -3px;
} */