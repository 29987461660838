@import url('./fonts/ping/stylesheet.css');

:root {
  --Tuna-900: #33333D;
  --Tuna-600: #5A5B6F;
  --Tuna-100: #EEEDF1;
}

html {
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

body {
  font-family: 'Ping LCG', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background: #F7F7F8;
}

body,
body.nomap {
  overflow-x: hidden !important;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background: lightgray;
}

body::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

/* Effect when click */
#root button:active {
  background: lightgray;
}

#root .form-select,
.form-select-sm,
#root .form-control,
.form-control,
.form-control:disabled,
form .searchWrapper {
  color: var(--Tuna-600);
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: var(--Tuna-600);
}

.form-select:disabled,
.form-control:disabled {
  background: var(--Tuna-100);
}

#root .form-select:focus,
.form-select-sm:focus,
#root .form-control:focus,
.form-control:focus,
.modal-header .btn-close:focus {
  border-color: #228489;
  box-shadow: unset;
}


/* This is to make the select look like the figma design but not looks good */
/* #root .form-select:focus,
.form-select-sm:focus {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
  border-bottom: unset;
} */

.signin:hover,
.signin:focus,
.signout .name:hover,
.signout .name:focus,
.options .dots:hover,
.options.show .dots {
  background: #5C5C64;
  border-color: #228489;
}

form .btn:hover,
.accountexport:hover,
.loanexport:hover,
.distexport:hover,
.documents .add:hover,
.modal .btn-primary:hover {
  background: #292931;
  border-color: transparent;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  color: #25252C;
}

.modal {
  color: var(--Tuna-900);
  font-size: 15px;
}

.modal-body {
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

.modal-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.modal-body::-webkit-scrollbar-track {
  background: lightgray;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

.modal-footer .btn {
  border-radius: 20px;
  padding: 2px 15px;
  font-size: 13px;
}

.modal-footer .btn-primary {
  background: var(--Tuna-900);
  border: 3px solid transparent;
}

.modal-footer .btn-secondary {
  padding: 4px 17px;
  background: var(--Tuna-100);
  color: var(--Tuna-900);
  border-color: lightgray;
}

.table {
  border: 1px solid #B5B6C4;
  border-radius: 6px;
}

.table thead {
  top: 0;
  box-shadow: 0 1px 0 #B5B6C4;
}

.table thead tr {
  border: unset;
}

.table tr,
.table tbody tr:nth-child(even) {
  background: #fff;
  border-color: #B5B6C4;
}

.table tbody tr:last-child {
  border-color: transparent;
}

.table tbody tr:last-child b {
  color: #25252C;
  font-size: 14px;
}

.table tbody tr:hover,
.dropdown-item:hover {
  background: #F7F7F8;
}

.table tbody td {
  color: var(--Tuna-600);
}

.table td,
.table th {
  color: #25252C;
  font-size: 12px;
  border: unset;
  padding: 10px;
}

.up, .down {
  font-size: 8px;
  color: gray;
}

.cards {
  background: #094D54;
  padding: 10px 15px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(108, 108, 108, 0.12);
}

.cards h6 {
  font-size: 12px;
  font-weight: 400;
}

.cards h4 {
  margin-bottom: unset;
}

.cards h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: unset;
}

.cards center {
  color: #fff;
  text-align: unset;
}

.cards center svg,
.cards svg {
  color: #fff !important;
  border-radius: 20px;
  border: 1px solid #fff;
  width: 15px;
  height: 15px;
  padding: 1px 0;
  margin-left: 3px;
  margin-top: -2px;
}

.filter p,
.totals p,
.account .details p {
  background: #25252C;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.15);
  white-space: normal;
  font-size: 13px;
}

.filter p::after,
.totals p::after,
.account .details p::after {
  border-color: transparent transparent #25252C transparent;
}

.signin {
  width: auto;
  top: 19px;
  right: 75px;
  border-radius: 20px;
  padding: 1.9px 30px;
  font-size: 13px;
  background: #5C5C64;
  color: #fff;
  border: 3px solid transparent;
}

.signin-modal, .mapblocker {
  top: 4.4rem;
  bottom: 2.2rem;
  left: 0;
}

form .btn {
  width: auto;
  margin-top: -5px;
  border-radius: 20px;
  padding: 2px 15px;
  font-size: 15px;
  background: var(--Tuna-900);
  color: #fff;
  border: 3px solid transparent;
}

.filts,
body.nomap .filts {
  background: var(--Tuna-900);
  height: 70px;
  padding: 0 30px;
}

.maptabs {
  left: 17rem;
}

.maptabs.level.investor,
.maptabs.level.reseller,
.maptabs.level.lender {
  display: flex;
}

/* .maptabs.level.investor #documents {
  margin-right: unset;
} */

.maptabs .nav-link {
  background: transparent;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 300;
  margin-right: 20px;
  border: unset;
  transition: unset;
  border-bottom: 3px solid transparent;
}

.maptabs .nav-link:hover,
.maptabs .nav-link.active {
  color: #fff;
  background: transparent;
  border-color: #228489;
}

.maptabs .nav-link.active {
  font-weight: 600;
}

.maptabs .nav-item:last-child .nav-link {
  border-bottom-right-radius: unset;
}

.phoenixlogo {
  margin-top: 12px;
  cursor: pointer;
}

.signout .name {
  position: fixed;
  right: 75px;
  top: 19px;
  border-radius: 20px;
  padding: 1.5px 15px;
  font-size: 13px;
  min-width: unset;
  max-width: unset;
  background: #5C5C64;
  border: 3px solid transparent;
  text-align: left;
  padding-right: 10px;
}

.signout .name svg {
  margin-top: -1px;
  margin-left: 15px;
  float: right;
}

.options .dots {
  margin-left: -32px;
  margin-bottom: -15px;
  padding: 2px;
  font-size: 12px;
  min-width: 30px;
  max-width: 30px;
  background: #5C5C64;
  border: 3px solid transparent;
}

.options .dots svg {
  width: 16px;
}

.options .dots path {
  color: #fff;
}

.footer {
  background: var(--Tuna-900);
  padding: 8px 30px;
  height: unset;
}

.footer .links {
  color: transparent;
  float: right;
}

.footer .copyright,
.footer .links {
  margin-top: unset;
}

.footer .copyright span,
.footer .links span {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}

.footer .links span {
  margin-left: 15px;
}

.myfilters {
  top: 70px;
  left: 13px;
  padding: 17px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
}

.account .currencies {
  width: auto;
  margin-right: -23px;
}

.account .currencies button,
.account .currencies button.active:hover {
  border-radius: 20px;
  background: var(--Tuna-100);
  color: var(--Tuna-900);
  width: 30px;
  height: 30px;
  border: unset;
}

.account .currencies button:active,
.account .currencies button.active {
  background: var(--Tuna-900);
  color: #fff;
}

.account .currencies button:last-child {
  margin-right: 15px;
}

.account .currencies button:nth-child(2) {
  font-size: unset;
  line-height: unset;
  padding-bottom: unset;
}

.account .currencies button:disabled {
  /* background: var(--Tuna-100);
  color: var(--Tuna-900); */
  display: none;
}

.account .redebt {
  margin-left: unset;
}

.account .redebt,
.filters2 .allaccounts,
.filters2 .allclasses,
.filters2 .allstatus,
.account .dropsearch {
  width: 200px;
}

.account .dropsearch {
  right: 19px;
}

.dropsearch .opts .result {
  padding: 0 10px;
}

.dropsearch .opts .result .dropdown-item {
  padding: 0 5px;
  margin: 5px 0;
}

.dropsearch .opts .res::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

.dropsearch .opts .res table {
  border-collapse: unset;
}

.dropsearch .opts .res .table td div {
  width: 123px;
}

.dropsearch .opts .res span {
  display: table;
  margin: 15px auto 5px;
  cursor: pointer;
}

.viewmode {
  width: 120px;
  position: relative;
  right: unset;
  margin-top: -16px;
  z-index: 1;
  float: right;
}

.acctpage .viewmode {
  position: absolute;
  right: 14.4rem;
}

.docspage .viewmode,
.commspage .viewmode,
.distspage .viewmode {
  display: none;
}

.clear {
  margin-top: 7px;
  margin-left: 0;
}

.filters2 .cleartext2,
.cleartext {
  background: var(--Tuna-100);
  border-radius: 20px;
  width: 30px;
  height: 30px;
  font-size: 0;
  margin-left: 12px;
  margin-top: 0;
  z-index: -1;
}

.filters .only {
  top: 70px;
  left: 13px;
  padding: 17px;
  z-index: 1;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.05);
}

.filters .only.level.investor {
  display: inherit;
}

.filters .only [class*="col"]:nth-child(3),
.filters .only [class*="col"]:nth-child(-n+2) {
  width: 250px;
}

#root .filters .only .features2 div {
  width: 226px;
}

.address input {
  border-radius: 20px;
}

#root .address button:active {
  background: transparent;
}

.address button svg {
  background: var(--Tuna-100);
  border-radius: 20px;
  width: 24px;
  height: 24px;
  margin-right: 1px;
  margin-bottom: 2px;
  padding: 5px 0;
  color: var(--Tuna-900) !important;
}

.filter svg {
  position: relative;
  margin-top: -22px;
  margin-right: -20px;
  color: var(--Tuna-900) !important;
  border-radius: 20px;
  border: 1px solid var(--Tuna-900);
  width: 16px;
  height: 16px;
  padding: 1px 0;
}

.filters .only .filter:nth-child(2) {
  margin-left: 12px;
}

.col-md-1 .clear {
  margin-top: 7px;
  margin-left: 5px;
  border-left: unset;
}

.accountpane {
  background: #fff;
}

.investorinfo {
  margin-top: unset !important;
  padding: 11rem 30px 1.5rem;
  background: #fff;
}

.investorinfo .details {
  border: unset;
  padding-bottom: unset;
  margin-top: unset;
}

.investorinfo .details.hide {
  height: 27px !important;
}

.investorinfo .accordion {
  width: auto;
  font-size: 25px;
  font-weight: 400;
  background: unset;
  margin-top: unset;
  padding: unset;
}

.investorinfo .accordion:active {
  background: unset !important;
}

.investorinfo .accordion:before {
  display: block;
  width: 22px;
  height: 22px;
  float: right;
  margin-top: 5px;
  margin-left: -17px;
  background: var(--Tuna-100);
  border-radius: 20px;
}

.investorinfo .accordion:after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  margin-bottom: 2px;
  margin-left: 12px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  -webkit-filter: opacity(1) drop-shadow(0 0 0 var(--Tuna-900));
  -moz-filter: opacity(1) drop-shadow(0 0 0 var(--Tuna-900));
  filter: opacity(1) drop-shadow(0 0 0 var(--Tuna-900));
}

.investorinfo .accordion.active:after {
  content: '';
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.investorinfo .info {
  margin-top: 5px;
  border-bottom: unset;
  padding: 0 15px;
  background: var(--Tuna-100);
  border-radius: 6px;
  margin-bottom: 3.5rem;
}

.investorinfo .info .col-md-3 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--Tuna-600);
  font-size: 12px;
}

.investorinfo .info .col-md-3 b {
  color: var(--Tuna-900);
  font-size: 15px;
  font-weight: 500;
}


.investorinfo .infonote,
.investorinfo h5,
.investorinfo .accts {
  padding: 0;
}

.investorinfo .infonote,
.investment .infonote {
  margin-bottom: -40px;
  color: var(--Tuna-600);
}

.investorinfo h5,
.investment h5 {
  margin-bottom: 35px;
  font-size: 17px;
}

.investorinfo .table thead {
  position: unset;
}

.investorinfo .table thead tr {
  border: unset;
  box-shadow: 0 1px 0 #B5B6C4;
}

.investorinfo .table thead tr:first-child {
  /* border-bottom: 1px solid #B5B6C4; */
  box-shadow: inset 0 -1px 0 #B5B6C4;
}

.investorinfo .table thead tr:nth-child(2) {
  position: sticky;
  top: -1px;
}

.investorinfo tr svg {
  color: #228489 !important;
  width: 16px;
  height: 16px;
}

.investorinfo tbody tr td span,
.documents .file {
  /* color: var(--Tuna-900); */
  color: #0d6efd;
  font-weight: 500;
}

.documents .file {
  display: inline;
}

.accountexport,
.loanexport,
.distexport,
.documents .add,
.btn:disabled {
  right: 30px;
  margin-top: -43px;
  border-radius: 20px;
  padding: 2px 15px;
  font-size: 13px;
  background: var(--Tuna-900);
  border: 3px solid transparent;
}

.distexport {
  position: fixed;
  right: 26.5rem;
}

.btn:disabled {
  opacity: 0.6;
}

.accountexport svg,
.loanexport svg,
.distexport svg,
.documents .add svg {
  width: 17px;
  height: 17px;
  margin-top: -2px;
  margin-right: 2px;
}

.account {
  margin-top: unset;
  padding: 16px 30px;
  background: linear-gradient(0deg,
    rgba(34, 132, 137, 0.19) -60.14%,
    rgba(34, 132, 137, 0.03) 49.29%);
}

.account .infos {
  border: unset;
  padding: unset;
  margin-top: unset;
}

.account .infos h4:nth-child(1),
.loans h4,
.stats h4 {
  font-size: 25px;
  font-weight: 400;
}

.infonote {
  margin-top: -8px;
  margin-bottom: 15px;
  color: var(--Tuna-600);
}

.account .details {
  margin-bottom: 23px;
  border-bottom: unset;
}

.account .details .col-md-3 {
  margin-top: unset;
  margin-bottom: unset;
}

.account .details .cards h6 {
  font-size: 12px;
}

.account .infos h5 {
  margin-bottom: 17px;
  font-size: 17px;
}

.filterbuttons {
  margin-bottom: 17px;
}

.infos .filterbuttons button {
  width: auto;
  text-align: left;
  background: unset;
  color: #B5B6C4;
  font-size: 13px;
  margin-right: 30px;
  border: unset;
}

.infos .filterbuttons button.active {
  /* color: var(--Tuna-600); */
  color: #0d6efd;
}

.infos .filterbuttons button.active:hover {
  color: #B5B6C4;
}

#root .infos .filterbuttons button:active {
  background: transparent;
  border-color: transparent;
  color: #B5B6C4;
}

.dates {
  margin-top: -43px;
  right: 198px;
}

.graph .blocker,
.graph .blocker1 {
  background: #ecf4f5;
}

.other .graph:nth-child(2) .blocker,
.other .graph:nth-child(2) .blocker1 {
  background: #f7fafb;
}

.account .note {
  margin-top: 5px;
  color: var(--Tuna-600);
}

.investment {
  padding: 0 30px 2rem;
  margin-top: 4.5rem;
  margin-bottom: unset;
}

.investment .details {
  border: unset;
  padding: unset;
  margin-top: unset;
}

.investment .details h5 {
  font-size: 25px;
  font-weight: 400;
}

.investment .infonote {
  margin-bottom: -48px;
}

.mappane {
  background: #fff;
  padding-top: 4rem;
}

.map {
  margin-top: unset;
}

.label {
  position: absolute;
  bottom: 38px;
  left: 3px;
  background: var(--Tuna-900);
  padding: 1px 10px;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
}

.categories {
  font-family: 'Ping LCG';
}

.categories .images [style*="url"] {
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 1);
  border-radius: 3px;
}

.categories .accordion,
.categories .accordion.active {
  font-size: 13px;
  background: #094D54;
  color: #fff;
}

.categories .accordion:before {
  /* content: '\005E';
  background-image: unset; */
  -webkit-filter: opacity(1) drop-shadow(0 2px 0 #fff);
  -moz-filter: opacity(1) drop-shadow(0 2px 0 #fff);
  filter: opacity(1) drop-shadow(0 2px 0 #fff);
}

.categories .accordion.active:before {
  margin-bottom: -1px;
}

.panel table {
  font-size: 11px;
  color: #000;
}

.gm-style-iw-d .bottom {
  background: var(--Tuna-900);
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
}

.loans {
  margin-top: 11.55rem;
  padding: 0 30px;
  min-height: calc(100vh - 220px);
}

.summary {
  width: unset;
  margin-top: unset;
  margin-bottom: 1.5rem;
  border: unset;
}

.summary .cards h6 {
  color:#fff;
  margin-top: unset;
}

body.nomap .loanlist {
  margin-top: unset;
}

body.nomap .loanlist thead {
  top: 0;
}

.loanlist {
  overflow: auto;
  position: absolute;
  top: 242px;
  right: 30px;
  bottom: 67px;
  left: 30px;
  width: auto;
}

.loanlist td div {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* .loanlist td div:hover {
  white-space: normal;
  overflow: visible;
} */

.images-modal .images [class*="col"]:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.images-modal .images [class*="col"]:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.images-modal .close {
  margin-top: -8px;
  margin-right: 20px;
  font-size: 26px;
}

.images-modal .close1 {
  display: none;
}

.images-modal .add {
  margin-top: -1px;
  margin-right: 20px;
  width: 24px;
  height: 24px;
}

.images-modal [class*="carousel"] {
  position: relative;
  margin-top: -110px;
  opacity: 0.6;
  z-index: 1;
  cursor: pointer;
}

.images-modal [class*="carousel"]:hover {
  opacity: 0.9;
}

.images-modal [class*="prev"] {
  float: left;
  margin-left: -60px;
}

.images-modal [class*="next"] {
  float: right;
  margin-right: -60px;
}

body.nomap .stats {
  margin-top: 11.2rem;
  padding: 0 30px;
  min-height: calc(100vh - 214.4px);
}

.totals {
  margin-top: 0;
  border: unset;
  min-height: 200px;
}

.totals .infonote {
  margin-top: -8px;
  margin-bottom: 15px;
}

.totals [class*="col"] {
  border: unset;
  margin-top: unset;
  margin-bottom: 1.5rem;
}

.totals .cards {
  background: #fff;
}

.totals .cards h6 {
  color: var(--Tuna-600);
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.totals .cards center {
  color: var(--Tuna-900);
}

.totals .cards svg {
  color: var(--Tuna-600) !important;
  border-color: var(--Tuna-600);
}

.statistics {
  margin-top: 2rem;
  margin-bottom: 3rem;
  background: #fff;
  padding: 15px 50px 10px;
  border-radius: 6px;
}

/* .statistics .investor:first-child,
.statistics .reseller:first-child {
  display: flex;
} */

.statistics .level.investor,
.statistics .level.reseller {
  display: flex;
}

.statistics .investor:nth-child(2) [class*="col"]:nth-child(n+3){
  display: none;
}

/* .statistics .admin:last-child [class*="col"]:last-child,
.statistics .investor:first-child [class*="col"]:last-child,
.statistics .reseller:first-child [class*="col"]:last-child {
  height: 217px;
  border-bottom: unset;
  margin-bottom: unset;
} */

.statistics .admin:last-child [class*="col"]:last-child {
  height: 217px;
  border-bottom: unset;
  margin-bottom: unset;
}

.statistics [class*="col"] {
  width: 50%;
  height: 170px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: unset;
  border-right: 1px solid var(--Tuna-100);
}

.statistics [class*="col"]:nth-child(3n-1) {
  padding-left: 60px;
  border-right: unset;
}

.statistics [class*="col"]:nth-child(3n) {
  width: 100%;
  height: 230px;
  padding-top: 30px;
  border: 1px solid var(--Tuna-100);
  border-right: unset;
  border-left: unset;
}

.statistics .title {
  text-align: left;
  margin-bottom: -12px;
  color: var(--Tuna-900);
  font-size: 23px;
}

.statistics [id*="canvasjs-react-chart-container"] {
  /* Uncomment this when starting to improve the charts */
  width: 180px !important;
  margin-left: -35px !important;
  /* width: 80% !important; */
}

.statistics [id*="canvasjs-react-chart-container"],
.statistics canvas {
  /* Uncomment this when starting to improve the charts */
  height: 180px !important;
  /* height: 200px !important; */
}

.statistics .legends {
  position: relative;
  top: -135px;
  left: 125px;
  width: 87%;
  z-index: 1;
}

.statistics [class*="col"]:nth-child(3n+1) .legends {
  width: 83%;
}

/* .statistics [class*="col"]:nth-child(3n-1) .legends {
  width: 76%;
} */

.statistics [class*="col"]:nth-child(3n-0) .legends {
  width: 93.95%;
}

.statistics .group {
  float: left;
  width: 50%;
  padding: 0 25px;
  margin-top: -12px;
}

.statistics [class*="col"]:nth-child(3n) .group {
  width: 33.33333333%;
  border-right: 1px solid var(--Tuna-100);
}

.statistics [class*="col"]:nth-child(3n) .group:last-child {
  border-right: unset;
}

.statistics .back {
  height: 21.5px;
  margin-bottom: -21.5px;
  border-radius: 3px;
  opacity: 0.1;
  border: 1px solid #000;
}

.statistics .legend {
  font-size: 13px;
  padding: 1px 5px 1px 20px;
  border-radius: 3px;
  margin-bottom: 1px;
}

.statistics .legend svg {
  position: absolute;
  margin-top: -2px;
  margin-left: -23px;
}

.statistics .legend span:nth-child(3) {
  float: right;
  font-weight: 500;
}

.statistics .blocker {
  margin-left: -35px;
}

.level.investor,
.maplink.level.reseller,
.loanlink.level.reseller {
  display: none;
}

.documents {
  margin-top: 7.4rem;
  padding: 0 30px;
  min-height: calc(100vh - 169.8px);
}

.documents .add {
  margin-top: unset;
  margin-bottom: 2.5rem;
}

.documents .add svg {
  margin-top: -3px;
}

.documents .section {
  border: unset;
}

.documents .accordion {
  width: auto;
  font-size: 25px;
  font-weight: 400;
  border: unset;
  padding: unset;
  cursor: unset;
  margin-bottom: 15px;
}

.documents .accordion:before {
  display: none;
}

.documents .accordion span {
  position: unset;
  float: right;
  cursor: pointer;
}

.documents .table {
  margin-top: unset;
  padding: unset;
}

.documents tbody tr td:last-child .file {
  display: unset;
  border: 1px solid #8D8EA3;
  border-radius: 20px;
  padding: 6px 15px;
  color: var(--Tuna-600);
}

.documents tbody tr td:last-child .file svg {
  width: 14px;
  height: 14px;
}

.documents .table th,
.documents .table td {
  width: 300px;
}

.dropdown-menu {
  color: var(--Tuna-600);
  font-size: 14px;
}

#root .result > .dropdown-item {
  border: 1px solid #B5B6C4;
  border-radius: 6px;
  width: 98.3%;
  margin: 5px auto;
  padding: 11.5px;
}

.dropdown-item {
  padding: 0 1rem;
}

form .searchWrapper {
  padding-top: 0;
  padding-bottom: 0;
}

form .chip {
  background: #094D54;
  line-height: unset;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
}

form .multiSelectContainer input,
form .multiSelectContainer input::placeholder {
  color: var(--Tuna-600);
  opacity: 1;
}

form .multiSelectContainer ul {
  max-height: 160px;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

form .multiSelectContainer ul::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

form .multiSelectContainer ul::-webkit-scrollbar-track {
  background: lightgray;
}

form .multiSelectContainer ul::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
}

form .multiSelectContainer li {
  color: var(--Tuna-600);
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

form .highlightOption,
form .multiSelectContainer li:hover {
  background: #F7F7F8;
  color: unset;
}