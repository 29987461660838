.zipcode {
  margin-top: 10px;
}

.zipcode form {
  margin-bottom: 10px;
}

.zipcode label,
.zipcode input {
  float: left;
  margin-right: .5rem;
}

.zipcode input {
  width: 300px;
}

.zipcode button {
  margin-top: 0 !important;
  padding: 1px 15px !important;
}

.zipcode .ziplist {
  overflow: auto;
}

.zipcode .ziplist table {
  width: 100%;
}

.zipcode .ziplist table td:first-child {
  width: 50%;
  font-weight: bold;
  text-align: right;
}