.modal-header {
  padding: .8rem 1rem;
}

.modal-header .btn-close {
  background-size: .8em;
}

.dropdown-menu {
  top: 5px !important;
}

.err {
  display: block;
  text-align: left;
  color: red !important;
  margin-top: 5px;
  margin-left: 5px;
  height: 12.8px;
  font-size: .8rem;
  line-height: 1;
}