.filters {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  height: 26.8px;
}

.filters select option:first-of-type {
  font-weight: bold;
}

.filters input,
.filters select {
  font-size: 0.7rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.filters .filter.state,
.filters .filter.accts,
.filters .filter.sizes,
.filters .filter.marks,
.filters .filter.stats,
.filters .filter.secus {
  display: none;
}

.about-modal .modal-body img {
  width: 100%;
  margin-bottom: 1rem;
}

.terms-modal .modal-body,
.privacy-modal .modal-body {
  max-height: 35rem;
  overflow-y: auto;
}

.terms-modal p,
.privacy-modal p {
  text-align: justify;
}

.terms-modal font,
.privacy-modal font {
  text-decoration: underline;
  color: #0d6efd;
}

.terms-modal b font,
.privacy-modal b font {
  color: unset;
}

.terms-modal section div,
.privacy-modal section div {
  border: 1px solid black;
  padding: 5px 10px;
  font-weight: bold;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 5px;
}

.privacy-modal .indent {
  margin-left: 30px;
  text-indent: -30px;
}

.privacy-modal .halfdent {
  margin-left: 15px;
  text-indent: -15px;
}

.privacy-modal .shifted {
  margin-left: 30px;
}

.privacy-modal .underline {
  color: unset;
}

.filter svg {
  float: right;
  margin-top: -22px;
  margin-right: -18px;
}

.filter p {
  background: gray;
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  width: 500px;
  color: #fff;
  margin-top: 2px;
  font-weight: normal;
  z-index: 1;
  font-size: 0.9rem;
}

.filter p::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.filter svg {
  cursor: pointer;
}

.filter svg:focus {
  outline: none;
}

.modal section {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Temporary hide submarket filter, delete this css if enable for all user accounts */
.features2 {
  width: auto;
  display: none;
}

.features2.admin {
  display: block;
}