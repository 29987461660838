body.nomap .footer {
  position: relative;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: small;
  padding: 0 2px 4px 2px;
  text-align: center;
  background: #fff;
  height: 38.33px;
  overflow: hidden;
}

.footer .copyright {
  float: left;
  margin-top: 10px;
}

.footer .links {
  color: gray;
  display: inline-block;
  margin-top: 10px;
}

.footer .links span {
  cursor: pointer;
}

.footer img {
  width: 170px;
  float: right;
  margin-top: 3px;
  margin-right: -10px;
}