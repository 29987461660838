.distributions {
  margin-top: 4.4rem;
  margin-bottom: 1rem;
  padding: 0 30px;
  min-height: calc(100vh - 121.8px);
}

.distributions h4 {
  position: sticky;
  top: 70px;
  background: #F7F7F8;
  padding-top: 3rem;
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: -2px;
}

.distributions .dists {
  overflow: clip;
  margin-top: 2px;
  margin-bottom: 0;
}

.distributions .dists thead {
  top: 163px;
  box-shadow: 0 1px 0 #B5B6C4, 0 -1px 0 #B5B6C4;
}

.distributions .dists th:nth-child(1),
.distributions .dists td:nth-child(1) {
  width: 350px;
}

.distributions .dists thead tr th:nth-child(2),
.distributions .dists tbody tr td:nth-child(2),
.distributions .dists thead tr th:nth-child(3),
.distributions .dists tbody tr td:nth-child(3),
.distributions .dists thead tr th:nth-child(6),
.distributions .dists tbody tr td:nth-child(6) {
  text-align: center;
}

.distributions table {
  width: 100%;
}

.distributions .dropsearch,
.distributions .quarter {
  position: sticky;
  top: 118px;
  width: 200px;
  float: right;
  margin-top: -45px;
  z-index: 1;
}

.distributions .quarter {
  right: 15.8rem;
  width: 150px;
}

.distributions .actions.investor {
  display: inherit;
}

.distributions .actions.investor .dropsearch {
  display: none;
}

.distributions .actions.investor .quarter {
  right: unset;
}

.distributions .actions.investor .distexport {
  right: 12.5rem;
}