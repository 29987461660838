.dropsearch .filter,
.dropsearch .opts {
  width: 100%;
}

.dropsearch .opts .dropdown-menu {
  width: 40rem;
  top: 0.2rem !important;
  left: unset !important;
}

.dropsearch .opts .toggler {
  visibility: hidden;
  position: absolute;
  margin-top: -1.8rem;
  right: -1px;
}

.dropsearch .opts .search {
  padding: 5px 10px;
}

.dropsearch .opts .search input {
  font-size: 0.8rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.dropsearch .opts .result {
  font-size: 0.8rem;
  padding-right: 7px;
  padding-left: 7px;
}

.dropsearch .opts .res {
  max-height: 20rem;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

.dropsearch .opts .res::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dropsearch .opts .res::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 20px;
}

.dropsearch .opts .res::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

.dropsearch .opts .res .table {
  margin-bottom: 0;
}

.dropsearch .opts .res .table tr:hover {
  background-color: unset;
}

.dropsearch .opts .res .table td div {
  width: 131px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .dropsearch .opts .res .table tr:hover td div {
  white-space: normal;
  overflow: visible;
} */
