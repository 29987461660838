body {
  overflow: hidden;
}

body.nomap {
  overflow: unset !important;
}

body.modal-open {
  overflow: hidden !important;
}

body.nomap .filts {
  position: fixed;
  top: 0;
  z-index: 2;
  background: #fff;
  height: 50px;
}

/* Commented out to switch tabs and filter */
/* body.nomap .maptabs {
  position: fixed;
  top: 53px;
  z-index: 3;
} */

body.nomap .loans .container-fluid {
  position: fixed;
  top: 40px;
  background: #fff;
}

body.nomap .loanexport {
  position: fixed;
  margin-top: -36px;
}

body.nomap .loanlist {
  margin-top: 10.97rem;
}

body.nomap .loanlist thead {
  top: 10.97rem;
}

body.nomap .stats {
  margin-top: 6.5rem;
}

/* Commented out to switch tabs and filter */
/* .maptabs {
  position: absolute;
  z-index: 1;
  border: none;
} */

.maptabs .nav-link {
  color: #fff;
  padding: 0.2rem 1rem;
  border-radius: unset;
  background: #418af6;
  font-size: 0.9rem;
  cursor: pointer;
}

.maptabs .nav-link:hover {
  color: #000;
  background: #dee2e6;
}

.maptabs .nav-item:last-child .nav-link {
  border-bottom-right-radius: 5px;
}

.maptabs .nav-link.active {
  color: #fff;
  background: #0a58ca;
  border-color: #0a58ca;
}

table {
  width: 99%;
  font-size: .8rem;
}

table thead {
  position: sticky;
  top: -1px;
  background: lightgray;
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
}

table th[id] {
  cursor: pointer;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table {
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 20px;
}

.table::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

.gm-style-iw {
  max-height: 660px !important;
}

.gm-style-iw button[title="Close"] {
  display: none !important;
}

.gm-style-iw-d {
  overflow: unset !important;
  padding-right: 12px;
  max-height: 642px !important;
}

.gm-style-iw-d .categories {
  margin: 22px 0 7px;
  width: 625px;
  max-height: 440px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
  padding: 0 7px;
}

.gm-style-iw-d .categories td:first-child {
  width: 240px;
}

.gm-style-iw-d .close {
  position: absolute;
  top: -2px;
  right: 9px;
  width: auto;
  font-size: 1.7rem;
  cursor: pointer;
  opacity: 0.8;
}

.gm-style-iw-d .bottom {
  float: right;
  margin-right: -1px;
  margin-bottom: 10px;
  background: #ccc;
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
  color: #606060;
}

.gm-style-iw-d .full {
  position: absolute;
  top: 10px;
  right: 30px;
  width: auto;
  font-size: .75rem;
  font-weight: 400;
  cursor: pointer;
  opacity: 0.8;
}

.gm-style-iw-d img {
  display: block;
  width: 20px;
  margin: auto;
}

.fullwindow {
  background: #fff;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 35px 10px;
  z-index: 2;
}

.fullwindow .categories {
  width: 100%;
  max-height: 94vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
  padding: 0 10px;
}

.fullwindow .categories .images [style*="url"] {
  height: 280px;
}

.fullwindow table td:first-child {
  width: 300px;
}

.fullwindow .close {
  position: fixed;
  top: -4px;
  right: 8px;
  font-size: 1.8rem;
  cursor: pointer;
  opacity: 0.8;
}

.fullwindow .close:hover,
.gm-style-iw-d .close:hover,
.gm-style-iw-d .full:hover {
  opacity: 1;
}

/* [src*="addresspin"] {
  display: none;
} */

.accordion {
  background-color: #eee;
  width: 100%;
  padding: 5px;
  border: none;
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 0.375rem;
}

.accordion:focus {
  outline: none;
}

.accordion.active, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 12px;
  background-color: white;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.accordion:before {
  content: '';
  display: inline-block;
  width: .9rem;
  height: .9rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  margin-right: 10px;
  margin-bottom: -3px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
}

.accordion.active:before {
  content: '';
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg);
}

.categories .images {
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 1px;
}

.categories .images [style*="url"] {
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lightgray;
  cursor: pointer;
}

.categories::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.categories::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 20px;
}

.categories::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

.panel table {
  margin-top: -3px;
  margin-bottom: 7px;
  font-size: 12px;
}

.panel table tr {
  border-bottom-width: 1px;
}

.panel table td {
  padding: 7px 0;
}

/* Switch tabs and filter */
.maptabs {
  position: fixed;
  top: 11px;
  z-index: 2;
  border: none;
}

.map {
  margin-top: 4rem;
}

.signin-modal, .mapblocker {
  top: 6.5rem;
}

.filters .only {
  position: absolute;
  top: 50px;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.infonote {
  margin-bottom: 1rem;
  font-size: 12px;
  color: #0d6efd;
}

form .multiSelectContainer input {
  margin-top: 4px;
  margin-bottom: 4px;
}

/* Uncomment below incase of loan list page restriction (reseller) */
/* .maptabs .level.reseller, */
.maptabs.level.lender .nav-item:nth-child(1) {
  display: none;
}