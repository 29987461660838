.graph .blocker,
.graph .blocker1 {
  position: relative;
  display: block;
  height: 1rem;
  width: 5rem;
  margin-top: -15px;
  background: #fff;
}

.graph .blocker1 {
  float: right;
}