.loans {
  margin-top: 3rem;
}

.loanexport {
  position: absolute;
  margin-top: -37px;
  right: 12px;
}

/* .loanlist thead tr th:nth-child(2) { */
.loanlist thead tr th:nth-child(1) {
  text-align: center;
}

.loanlist tr {
  background: unset;
}

td div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

td div::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 20px;
}

td div::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

td div {
  max-height: 80px;
  overflow: hidden;
}

td div:hover {
  overflow-y: auto;
}

td div {
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

.up, .down {
  font-size: 6px;
  color: gray;
}

.up.active, .down.active {
  color: #000;
}

.up {
  position: relative;
  top: -6px;
  margin-left: -5px;
}

.loanlist td div svg {
  margin: auto;
  display: flex;
  cursor: pointer;
}