.address input {
  padding-right: 2rem;
}

.address button {
  background: transparent;
  border: none;
  float: right;
  margin-top: -28px;
  margin-right: -3px;
}