@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-BoldItalic.eot');
    src: local('Ping LCG Bold Italic'), local('PingLCG-BoldItalic'),
        url('PingLCG-BoldItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-BoldItalic.woff2') format('woff2'), */
        url('PingLCG-BoldItalic.woff') format('woff'),
        url('PingLCG-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Bold.eot');
    src: local('Ping LCG Bold'), local('PingLCG-Bold'),
        url('PingLCG-Bold.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Bold.woff2') format('woff2'), */
        url('PingLCG-Bold.woff') format('woff'),
        url('PingLCG-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-RegularItalic.eot');
    src: local('Ping LCG Regular Italic'), local('PingLCG-RegularItalic'),
        url('PingLCG-RegularItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-RegularItalic.woff2') format('woff2'), */
        url('PingLCG-RegularItalic.woff') format('woff'),
        url('PingLCG-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-ThinItalic.eot');
    src: local('Ping LCG Thin Italic'), local('PingLCG-ThinItalic'),
        url('PingLCG-ThinItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-ThinItalic.woff2') format('woff2'), */
        url('PingLCG-ThinItalic.woff') format('woff'),
        url('PingLCG-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-ExtraLight.eot');
    src: local('Ping LCG ExtraLight'), local('PingLCG-ExtraLight'),
        url('PingLCG-ExtraLight.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-ExtraLight.woff2') format('woff2'), */
        url('PingLCG-ExtraLight.woff') format('woff'),
        url('PingLCG-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-HeavyItalic.eot');
    src: local('Ping LCG Heavy Italic'), local('PingLCG-HeavyItalic'),
        url('PingLCG-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-HeavyItalic.woff2') format('woff2'), */
        url('PingLCG-HeavyItalic.woff') format('woff'),
        url('PingLCG-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Thin.eot');
    src: local('Ping LCG Thin'), local('PingLCG-Thin'),
        url('PingLCG-Thin.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Thin.woff2') format('woff2'), */
        url('PingLCG-Thin.woff') format('woff'),
        url('PingLCG-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Heavy.eot');
    src: local('Ping LCG Heavy'), local('PingLCG-Heavy'),
        url('PingLCG-Heavy.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Heavy.woff2') format('woff2'), */
        url('PingLCG-Heavy.woff') format('woff'),
        url('PingLCG-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Regular.eot');
    src: local('Ping LCG Regular'), local('PingLCG-Regular'),
        url('PingLCG-Regular.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Regular.woff2') format('woff2'), */
        url('PingLCG-Regular.woff') format('woff'),
        url('PingLCG-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-MediumItalic.eot');
    src: local('Ping LCG Medium Italic'), local('PingLCG-MediumItalic'),
        url('PingLCG-MediumItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-MediumItalic.woff2') format('woff2'), */
        url('PingLCG-MediumItalic.woff') format('woff'),
        url('PingLCG-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Black.eot');
    src: local('Ping LCG Black'), local('PingLCG-Black'),
        url('PingLCG-Black.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Black.woff2') format('woff2'), */
        url('PingLCG-Black.woff') format('woff'),
        url('PingLCG-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG Hairline';
    src: url('PingLCG-HairlineItalic.eot');
    src: local('Ping LCG Hairline Italic'), local('PingLCG-HairlineItalic'),
        url('PingLCG-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-HairlineItalic.woff2') format('woff2'), */
        url('PingLCG-HairlineItalic.woff') format('woff'),
        url('PingLCG-HairlineItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Medium.eot');
    src: local('Ping LCG Medium'), local('PingLCG-Medium'),
        url('PingLCG-Medium.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Medium.woff2') format('woff2'), */
        url('PingLCG-Medium.woff') format('woff'),
        url('PingLCG-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-BlackItalic.eot');
    src: local('Ping LCG Black Italic'), local('PingLCG-BlackItalic'),
        url('PingLCG-BlackItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-BlackItalic.woff2') format('woff2'), */
        url('PingLCG-BlackItalic.woff') format('woff'),
        url('PingLCG-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG Hairline';
    src: url('PingLCG-Hairline.eot');
    src: local('Ping LCG Hairline'), local('PingLCG-Hairline'),
        url('PingLCG-Hairline.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Hairline.woff2') format('woff2'), */
        url('PingLCG-Hairline.woff') format('woff'),
        url('PingLCG-Hairline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-ExtraLightItalic.eot');
    src: local('Ping LCG ExtraLight Italic'), local('PingLCG-ExtraLightItalic'),
        url('PingLCG-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-ExtraLightItalic.woff2') format('woff2'), */
        url('PingLCG-ExtraLightItalic.woff') format('woff'),
        url('PingLCG-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-LightItalic.eot');
    src: local('Ping LCG Light Italic'), local('PingLCG-LightItalic'),
        url('PingLCG-LightItalic.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-LightItalic.woff2') format('woff2'), */
        url('PingLCG-LightItalic.woff') format('woff'),
        url('PingLCG-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ping LCG';
    src: url('PingLCG-Light.eot');
    src: local('Ping LCG Light'), local('PingLCG-Light'),
        url('PingLCG-Light.eot?#iefix') format('embedded-opentype'),
        /* url('PingLCG-Light.woff2') format('woff2'), */
        url('PingLCG-Light.woff') format('woff'),
        url('PingLCG-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

