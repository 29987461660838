.filters2 select option:first-of-type {
  font-weight: bold;
}

.filters2 input,
.filters2 select {
  font-size: 0.7rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.filters2 .allclasses,
.filters2 .allstatus {
  width: 18%;
}

.filters2 .cleartext2 {
  width: auto;
  margin-top: 5px;
  margin-left: 31px;
  font-size: 0.8rem;
  color: gray;
  cursor: pointer;
}

.filters2 .allaccounts {
  width: 30%;
}