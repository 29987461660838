@media only screen and (max-width : 1829px) {
  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 214px;
  }

  #root .filters .only.admin .features2 div {
    width: 190px;
  }
}

@media only screen and (max-width : 1614px) {
  .filters .only [class*="col"]:nth-child(3),
  .filters .only [class*="col"]:nth-child(-n+2) {
    width: 200px;
  }

  #root .filters .only .features2 div {
    width: 176px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 190px;
  }

  #root .filters .only.admin .features2 div {
    width: 166px;
  }

  .totals [class*="col"] {
    width: 25%;
  }

  .statistics [class*="col"]:nth-child(3n+1) .legends {
    width: 82%;
  }

  .statistics [class*="col"]:nth-child(3n-1) {
    padding-left: 50px;
  }
}

@media only screen and (max-width : 1469px) {
  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 173px;
  }

  #root .filters .only.admin .features2 div {
    width: 149px;
  }
}

@media only screen and (max-width : 1369px) {
  .filters .only [class*="col"]:nth-child(-n+2) {
    width: 189px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 163px;
  }

  #root .filters .only.admin .features2 div {
    width: 139px;
  }

  .filters input,
  .filters select {
    padding-left: 10px;
    padding-right: 30px;
  }

  .statistics [class*="col"]:nth-child(3n+1) .legends {
    width: 80%;
  }

  .statistics [class*="col"]:nth-child(3n-1) {
    padding-left: 35px;
  }
}

@media only screen and (max-width : 1314px) {
  .filters .only [class*="col"]:nth-child(-n+2),
  .account .redebt,
  .filters2 .allaccounts,
  .filters2 .allclasses,
  .filters2 .allstatus,
  .account .dropsearch {
    width: 170px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 153px;
  }

  #root .filters .only.admin .features2 div {
    width: 129px;
  }

  .acctpage .viewmode {
    right: 12.4rem;
  }

  .statistics .legends {
    left: 100px;
    width: 90%;
  }

  .statistics [class*="col"]:nth-child(3n+1) .legends {
    width: 83%;
  }

  .statistics [class*="col"]:nth-child(3n-0) .legends {
    width: 95.3%;
  }
}

@media only screen and (max-width : 1249px) {
  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 148px;
  }

  #root .filters .only.admin .features2 div {
    width: 124px;
  }
}

@media only screen and (max-width : 1219px) {
  .filters .only [class*="col"]:nth-child(3),
  .filters .only [class*="col"]:nth-child(-n+2),
  .account .redebt,
  .filters2 .allaccounts,
  .filters2 .allclasses,
  .filters2 .allstatus {
    width: 153px;
    padding-right: 5px;
  }

  .filters .only [class*="col"].address {
    width: 170px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 137px;
  }

  #root .filters .only.admin .features2 div {
    width: 125px;
  }

  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    padding-right: 5px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"].redebt,
  .filters .only.admin [class*="col"].address,
  #root .filters .only.admin .features2 div,
  .features2 {
    padding-right: 0;
  }

  .maptabs.admin #myAccounts,
  .maptabs.admin #dynamicMap,
  .maptabs.admin #commissions,
  .maptabs.admin #distributions {
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .viewmode {
    width: 105px;
  }

  .acctpage .viewmode {
    right: 12.05rem;
  }

  .newloans,
  .rollBackLoans {
    margin-left: 10px;
  }

  .totals [class*="col"] {
    width: 33.33333333%;
  }

  .statistics {
    padding: 15px 47px 10px;
  }

  .statistics [class*="col"] {
    width: 100%;
    height: 200px;
    border-right: unset;
    border-bottom: 1px solid var(--Tuna-100);
  }

  .statistics [class*="col"]:nth-child(3n-1) {
    padding-left: unset;
  }

  .statistics [class*="col"]:nth-child(3n) {
    border-top: unset;
    padding-top: unset;
    height: 200px;
  }

  /* .statistics .admin:last-child [class*="col"]:last-child,
  .statistics .investor:first-child [class*="col"]:last-child,
  .statistics .reseller:first-child [class*="col"]:last-child {
    height: 190px;
  } */

  .statistics .level:last-child [class*="col"]:last-child {
    height: 190px;
  }

  .statistics .legends,
  .statistics [class*="col"]:nth-child(3n-0) .legends,
  .statistics [class*="col"]:nth-child(3n+1) .legends {
    width: 94%;
  }

  .statistics .group {
    width: 33.33333333%;
  }
}

@media only screen and (max-width : 1159px) {
  .maptabs.admin #myAccounts,
  .maptabs.admin #dynamicMap,
  .maptabs.admin #commissions,
  .maptabs.admin #distributions {
    width: 75px;
  }
}

@media only screen and (max-width : 1119px) {
  .filters .only [class*="col"]:nth-child(3) {
    width: 170px;
  }

  .filters .only [class*="col"]:nth-child(-n+2),
  .account .redebt,
  .filters2 .allaccounts,
  .filters2 .allclasses,
  .filters2 .allstatus,
  .account .dropsearch {
    width: 144px;
  }

  .filters .only [class*="col"].address {
    width: 168px;
    padding-right: 3px;
  }

  .filters .only.admin [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2) {
    width: 129px;
  }

  #root .filters .only.admin .features2 div {
    width: 117px;
  }

  .phoenixlogo {
    margin-top: 14px;
    margin-left: -20px;
    width: 190px;
  }

  .maptabs {
    left: 14rem;
  }

  .maptabs.admin #myAccounts,
  .maptabs.admin #dynamicMap,
  .maptabs.admin #commissions,
  .maptabs.admin #distributions {
    width: 80px;
  }

  .signout .name {
    right: 51px;
  }

  .options .dots {
    margin-left: -13px;
  }

  .acctpage .viewmode {
    right: 9.2rem;
  }

  .account .dropsearch {
    right: -3px;
  }

  .filters .only,
  .myfilters {
    left: 10px;
    padding: 17px 0;
  }

  .rollBackLoans {
    margin-right: -23px;
  }

  .footer {
    padding: 8px 10px;
  }
}

@media only screen and (max-width : 1059px) {
  .maptabs.admin #myAccounts,
  .maptabs.admin #dynamicMap,
  .maptabs.admin #commissions,
  .maptabs.admin #distributions {
    width: 70px;
  }
}

@media only screen and (max-width : 1023px) {
  html {
    overflow: hidden;
  }

  body {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  body.mobile {
    overflow: hidden !important;
  }

  .modal-title {
    font-size: 1.05rem;
  }

  .modal-title img {
    width: 155px;
    top: 12px;
  }

  .sectionModal,
  .fileModal {
    top: 4.4rem;
  }

  .signin-modal .modal-dialog-centered,
  .sectionModal .modal-dialog-centered,
  .fileModal .modal-dialog-centered {
    min-height: unset;
  }

  .about-modal .modal-body,
  .terms-modal .modal-body,
  .disclaimer-modal .modal-body,
  .privacy-modal .modal-body {
    max-height: 32rem;
    overflow-y: auto;
  }

  .maptabs {
    top: 70px;
    right: 0;
    left: 0;
    z-index: 2;
    background: #fff;
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    display: flex;
    justify-content: space-between;
  }

  .maptabs:after {
    content: '';
    position: fixed;
    top: 115px;
    right: 0;
    left: 0;
    border-bottom: 1px solid lightgray;
  }

  .maptabs::-webkit-scrollbar,
  .filter svg,
  .cards center svg,
  .cards svg {
    display: none;
  }

  .maptabs .nav-item {
    /* Works only in chrome */
    /* display: ruby; */

    /* Works in chrome and firefox */
    display: table-cell;
    white-space: nowrap;
  }

  .level.investor {
    display: none;
  }

  .maptabs .nav-item:last-child .nav-link {
    margin-right: unset;
  }

  .maptabs .nav-link,
  .maptabs .nav-link:hover,
  .maptabs .nav-link.active {
    color: var(--Tuna-900);
  }

  .maptabs .nav-link:hover {
    border-color: transparent;
  }

  .maptabs .nav-link.active {
    border-color: #228489;
  }

  .maptabs.admin #myAccounts,
  .maptabs.admin #dynamicMap,
  .maptabs.admin #commissions,
  .maptabs.admin #distributions {
    width: unset;
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  }

  .filterIcon {
    display: block;
    width: auto;
    color: lightgray;
    position: fixed;
    right: 105px;
    top: 22px;
    cursor: pointer;
  }

  .signout .name {
    width: 60px;
    height: 29px;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* .signout .name,
  .signout .name:focus {
    background: #5C5C64 !important;
  } */

  .signout .name svg {
    margin-top: -2px;
    background: #5C5C64;
    position: fixed;
    right: 54px;
    width: 23px;
    height: 23px;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
  }

  .filts {
    position: relative;
    z-index: 3 !important;
  }

  .filters .only,
  .filters .only.level.investor,
  .myfilters {
    display: none;
    padding: 50px 0 10px;
    left: 12px;
    z-index: 2;
  }

  .filters .only .close,
  .myfilters .close,
  .fullwindow .close1,
  .images-modal .close1,
  .commissions .dropsearch .close,
  .distributions .dropsearch .close {
    background: var(--Tuna-900);
    border-color: transparent;
    color: #fff;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 15px;
    width: auto;
  }

  .mobile .phoenixlogo {
    pointer-events: none;
  }

  .account .currencies {
    position: absolute;
    top: 10px;
  }

  .account .currencies button.active:hover {
    background: var(--Tuna-900);
    color: #fff;
  }

  .filters .only [class*="col"]:nth-child(-n+2),
  .filters .only [class*="col"]:nth-child(3),
  .filters .only.admin [class*="col"]:nth-child(-n+2),
  .filters .only.admin [class*="col"]:nth-child(3),
  #root .filters .only.admin .features2 div,
  .account .redebt,
  .filters2 .allaccounts,
  .filters2 .allclasses,
  .filters2 .allstatus {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 12px;
  }

  .filters .only [class*="col"]:nth-child(4),
  .filters .only [class*="col"]:nth-child(5),
  .filters2 {
    width: 100%;
  }

  .filters .only .filter:nth-child(2) {
    margin-left: unset;
    margin-bottom: unset;
  }

  .filters .only .filter.admin:nth-child(2),
  .filters .only .filter.investor:nth-child(2) {
    margin-bottom: 40px;
  }

  .filters .only .filter.investor:nth-child(2) {
    display: inherit;
  }

  .filters2 .cleartext2,
  .cleartext,
  .clear {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .clear {
    right: 52px;
  }

  .viewmode {
    display: none;
    position: absolute;
    top: 377px;
    right: 12px;
    left: 12px;
    width: unset;
    z-index: 3;
  }

  .viewmode.investor {
    top: 337px;
  }

  .acctpage .viewmode {
    top: 297px;
    right: 12px;
  }

  .account .dropsearch {
    width: 100%;
    position: relative;
    right: unset;
  }

  .account .dropsearch.admin {
    margin-top: 40px;
  }

  .dropsearch .opts .dropdown-menu {
    position: fixed !important;
    top: -5px !important;
    right: -1px !important;
    bottom: -5px !important;
    left: -1px !important;
    width: unset !important;;
    transform: unset !important;
  }

  .dropsearch .opts .search {
    margin-top: 24px;
  }

  .dropsearch .opts .result {
    position: absolute;
    top: 72px;
    right: 0;
    left: 0;
    bottom: 0;
  }

  #root .result > .dropdown-item {
    width: 100%;
  }

  .dropsearch .opts .res {
    max-height: unset;
    position: absolute;
    top: 49px;
    right: 5px;
    left: 5px;
    bottom: 55px;
  }

  .dropsearch .opts .res .table {
    overflow: hidden;
  }

  .dropsearch .close {
    position: absolute;
    right: 0;
    bottom: 17px;
  }

  .dropsearch .dsCloseIcon {
    position: fixed;
    top: -4px;
    right: 8px;
    font-size: 1.8rem;
    cursor: pointer;
    /* opacity: 0.8; */
  }

  .newloans,
  .rollBackLoans {
    display: none;
    position: absolute;
    margin-top: -20px;
    z-index: 3;
  }

  .newloans {
    left: 0;
  }

  .rollBackLoans {
    left: 34px;
  }

  .mobileBackDrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    display: none;
  }

  .docspage .filterIcon,
  .commspage .filterIcon,
  .distspage .filterIcon {
    pointer-events: none;
    color: transparent;
  }

  .investorinfo {
    padding: 9rem 10px 1rem;
  }

  .investorinfo .accordion,
  .account .infos h4:nth-child(1),
  .investment .details h5,
  .loans h4,
  .stats h4,
  .cards h2,
  .cards h4,
  .documents .accordion,
  .commissions h4,
  .distributions h4 {
    font-size: 20px;
  }

  .investorinfo .accordion::before {
    width: 20px;
    height: 20px;
    margin-top: 1px;
    margin-left: -16px;
  }

  .investorinfo .accordion::after {
    margin-left: 10px;
  }

  .accountexport,
  .loanexport,
  .distexport {
    right: 10px;
    font-size: 12px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    border-right: 20px solid transparent;
  }

  .account {
    margin-top: unset;
    padding: 16px 10px;
  }

  .account .details .col-md-3 {
    margin-bottom: 10px;
  }

  .dates {
    margin-top: 0px;
    right: 10px;
    left: 10px;
  }

  .dates .date {
    width: 50%;
  }

  .account .graph {
    margin-top: 75px;
  }

  .account .other .graph:last-child {
    margin-top: unset;
  }

  .investment {
    padding: 0 10px;
  }

  .loans {
    margin-top: 9rem;
    padding: 0 10px;
    min-height: calc(100vh - 179.8px);
  }

  .summary {
    margin-bottom: 1rem;
  }

  .summary [class*="col"] {
    margin-bottom: 10px;
  }

  body.nomap .loanexport {
    position: absolute;
  }

  .loanlist {
    top: 267px;
    right: 10px;
    left: 10px;
    bottom: 36px;
  }

  .images-modal {
    padding: 0 10px;
  }

  .images-modal .modal-dialog {
    margin-bottom: unset;
  }

  .images-modal .images [class*="col"] {
    position: relative;
    margin-bottom: 10px;
    border-radius: 6px;
  }

  .images-modal .close,
  .images-modal .add {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 28px;
  }

  .images-modal .add {
    top: 11px;
    width: 26px;
    height: 26px;
  }

  .images-modal [class*="carousel"] {
    display: none;
  }

  .images-modal .close1 {
    display: block;
  }

  body.nomap .stats {
    margin-top: 9rem;
    padding: 0 10px;
  }

  .totals [class*="col"] {
    width: 50%;
  }

  .totals [class*="col"]:nth-child(n+7) h2 {
    font-size: 22px;
  }

  .totals .cards h6 {
    white-space: unset;
    height: 25px;
  }

  .totals .cards h6 br {
    display: none;
  }

  /* All commented out in .statistics are moved to larger break point */

  .statistics {
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* padding: 15px 47px 10px; */
  }

  .statistics [class*="col"] {
    position: relative;
    /* width: 100%; */
    /* border-right: unset; */
    height: unset;
    /* border-bottom: 1px solid var(--Tuna-100); */
  }

  /* .statistics [class*="col"]:nth-child(3n-1) {
    padding-left: unset;
  } */

  .statistics [class*="col"]:nth-child(3n) {
    /* border-top: unset; */
    height: unset;
    /* padding-top: unset; */
  }

  /* .statistics .admin:last-child [class*="col"]:last-child,
  .statistics .investor:first-child [class*="col"]:last-child,
  .statistics .reseller:first-child [class*="col"]:last-child {
    height: unset;
  } */

  .statistics .level:last-child [class*="col"]:last-child {
    height: unset;
  }

  .statistics .title {
    font-size: 21px;
  }

  .statistics .legends {
    top: unset;
    left: 115px;
    width: 70%;
    margin-top: -147px;
    margin-bottom: 15px;
    min-height: 146px;
  }

  .statistics .legends::after {
    content: '';
    display: table;
    clear: both;
  }

  .statistics [class*="col"]:nth-child(3n+1) .legends,
  .statistics [class*="col"]:nth-child(3n-1) .legends,
  .statistics [class*="col"]:nth-child(3n) .legends {
    width: 70%;
  }

  .statistics .group,
  .statistics [class*="col"]:nth-child(3n) .group {
    width: 100%;
    border-right: unset;
    margin-top: unset;
    padding: 0 25px 0 10px;
  }

  .statistics .legend span:nth-child(2) {
    display: inline-block;
    max-width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .statistics .blocker {
    position: absolute;
    width: 100%;
    top: 173px;
    bottom: unset;
  }

  .documents {
    margin-top: 9rem;
    margin-bottom: unset;
    padding: 0 10px;
    min-height: calc(100vh - 179.8px);
  }

  .documents .add {
    font-size: 12px;
    margin-bottom: 2rem;
  }

  .documents .section {
    margin-bottom: 2rem;
    padding-bottom: unset;
  }

  .documents .section:last-child {
    margin-bottom: 1rem;
  }

  .documents .accordion {
    margin-bottom: 10px;
  }

  .commissions {
    margin-top: 7rem;
    padding: 0 10px;
    min-height: calc(100vh - 163.8px);
  }

  .commissions h4 {
    top: 112px;
    padding-top: 2rem;
    padding-bottom: 10px;
  }

  .admin.commissions h4 {
    padding-bottom: 52px;
  }

  .commissions .dropsearch {
    top: 178px;
    width: 100%;
    margin-top: -40px;
    z-index: 2;
    /* The z-index will be updated to 3
    in mobile upon showing the options
    to display the options fully on
    the top of all elements */
  }

  .commissions .comms {
    overflow: auto;
    position: absolute;
    top: 176px;
    right: 10px;
    bottom: 3.3rem;
    left: 10px;
    max-height: unset;
    width: unset;
  }

  .admin.commissions .comms {
    top: 218px;
  }

  .commissions .comms thead {
    /* top: 221px; */
    top: 0;
  }

  .documents tbody tr td:last-child .file,
  .commissions .file {
    white-space: nowrap;
  }

  .distributions {
    margin-top: 7rem;
    padding: 0 10px;
    min-height: calc(100vh - 163.8px);
  }

  .distributions h4 {
    top: 112px;
    padding-bottom: 52px;
    padding-top: 2rem;
  }

  .distributions .dropsearch,
  .distributions .quarter {
    top: 178px;
    width: 49%;
  }

  .distributions .quarter {
    float: left;
  }

  .distexport {
    right: 0.6rem;
    top: 182px;
  }

  .distributions .dists {
    overflow: auto;
    position: absolute;
    top: 218px;
    right: 10px;
    bottom: 3.3rem;
    left: 10px;
    max-height: unset;
    width: unset;
  }

  .distributions .dists thead {
    top: 0;
  }

  .distributions .dists td:nth-child(1) div {
    width: 250px;
  }

  .distributions .dists td:nth-child(2) div,
  .distributions .dists td:nth-child(3) div {
    width: 100px;
  }

  .signin {
    right: 51px;
    padding: 1.9px 8px;
  }

  .fullwindow {
    padding: 35px 0;
    z-index: 3;
    overflow-y: hidden;
    border-bottom: 35px solid #fff;
  }

  .fullwindow .categories {
    padding: 0 10px 10px;
    position: fixed;
    top: 36px;
    bottom: 36px;
  }

  .fullwindow .categories .images [style*="url"] {
    height: 150px;
    margin-bottom: 10px;
  }

  .fullwindow table td:first-child {
    width: 230px;
  }

  .images-modal .close1,
  .fullwindow .close1 {
    position: fixed;
    right: 0;
    bottom: 5px;
  }

  .footer .links {
    display: none;
  }
}

/* Small Devices, Tablets */
/* iPad Mini */
@media only screen and (max-width : 767px) {
  .loanlist {
    /* position: static;
    max-height: 28rem; */
    position: absolute;
    top: 176px;
    bottom: 2.3rem;
  }
}

@media only screen and (max-width : 625px) {
  .maptabs,
  .maptabs.level.investor {
    display: block;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

/* Surface Duo */
/* @media only screen and (max-width : 540px) { */
@media only screen and (max-width : 542px) {
  .statistics .admin:last-child [class*="col"]:nth-child(4) .blocker {
    top: 200px;
  }

  .maptabs.level.reseller {
    display: block;
  }
}

/* iPhone XR but base on iPhone SE (375)  */
/* @media only screen and (max-width : 414px) { */
@media only screen and (max-width : 427px) {
  /* Commented out because investor has only 3 tabs */
  /* .maptabs.level.investor {
    display: block;
  } */
}

/* Extra Small Devices, Phones */
/* Galaxy Fold */
/* @media only screen and (max-width : 280px) { */
@media only screen and (max-width : 374px) {
  .modal-title img {
    display: none;
  }

  .statistics .legend span:nth-child(2) {
    max-width: 87px;
  }

  .table thead,
  .investorinfo .table thead tr:nth-child(2) {
    top: 0;
    box-shadow: 0 1px 0 #B5B6C4, 0 -1px 0 #B5B6C4;
  }

  .investorinfo .table thead {
    box-shadow: unset;
  }
}