.loader div {
  position: fixed;
  z-index: 1055;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
}

.loader img {
  position: fixed;
  z-index: 1055;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2rem;
}

.loader span {
  position: fixed;
  z-index: 1055;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 25px;
  text-align: center;
  color: #fff;
}