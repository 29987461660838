.images-modal .modal-content {
  background: transparent;
  border: none;
}

.images-modal .modal-body {
  padding: 0.1rem;
}

.images-modal .close {
  width: 0;
  height: 0;
  display: block;
  float: right;
  margin-top: -23px;
  margin-right: 26px;
  font-size: 2.5rem;
  color: #000;
  opacity: 0.8;
  cursor: pointer;
}

.images-modal .close:hover {
  opacity: 1;
}

.images-modal .add {
  display: block;
  float: right;
  margin-top: -3px;
  margin-right: 25px;
  color: #0d6efd;
  opacity: 0.8;
  cursor: pointer;
}

.images-modal .add:hover {
  opacity: 1;
}

.images-modal .images {
  justify-content: center;
}

.images-modal .images [class*="col"] {
  background: #fff;
  padding: 3px;
}

.images-modal .images [class*="col"]:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.images-modal .images [class*="col"]:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.images-modal .images [style*="url"] {
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lightgray;
  cursor: pointer;
}

.images-modal .images .delete {
  color: #fc6400;
  position: absolute;
  top: 6px;
  cursor: pointer;
}

.images-modal .images .name {
  margin-top: -26px;
  float: inline-start;
  text-align: center;
  color: #fff;
  width: 100%;
}

.slider-backdrop.inImages {
  opacity: 0.6 !important;
}